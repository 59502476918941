.datefieldText {
    color: lightskyblue !important;
    font-weight: 600;
}

.serviceWasherfieldText {
    color: #4a4a4a !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.onliefieldText {
    color: #4a4a4a !important;
}

.offlinefieldText {
    color: #4a4a4a !important;
}

.pendingStatusfieldText {
    color: #3498db !important;
}

.acceptedStatusfieldText {
    color: #145388 !important;
}

.processStatusfieldText {
    color: #3195a5 !important;
}

.ratedStatusfieldText {
    color: #b69329 !important;
}

.completedStatusfieldText {
    color: #3e884f !important;
}

.rejectedStatusfieldText {
    color: #c43d4b !important;
}

.react-datepicker-wrapper .react-datepicker__input-container .customDatePickerBppkings {
    margin: 0;
    text-align: center;
    border-radius: 20px !important;
    padding: 0 !important;
    cursor: pointer;
    height: 25px !important;
    background-color: transparent !important;
    border: 1px solid #8f8f8f !important;
}

.newBookingBadge {
    top: -32px;
    left: -50px;
    position: relative;
}

.createdBadge {
    top: -32px;
    left: -10px;
    position: relative;
}

.btn.btn-outline-danger.disabled:hover {
    color: #c43d4b !important;
}

.form-control:disabled {
    background: white;
}

.ReactTable .rt-th, .ReactTable .rt-td {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
}

.redatepicker {
    min-width: 400px !important;
}

.custoumSelect {
    width: 100% !important;
}

.rejectedStatusfieldText .hoverCanceled .canceledComment {
    display: none;
}

.rejectedStatusfieldText .hoverCanceled:hover .canceledComment {
    display: block;
    border: 1px solid;
    position: absolute;
    background-color: white;
    margin-top: -15px;
    margin-left: 60px;
    padding: 10px;
    color: #4a4a4a;
    border-radius: 14px;
}

.ratedStatusfieldText .hoverRating .ratingComment {
    display: none;
}

.ratedStatusfieldText .hoverRating:hover .ratingComment {
    display: block;
    border: 1px solid;
    position: absolute;
    background-color: white;
    margin-top: -40px;
    margin-left: 45px;
    padding: 10px;
    color: #4a4a4a;
    border-radius: 14px;
}

.react-rater-star {
    font-size: 12px !important;
}

@media only screen and (max-width: 820px) {
    input {
        font-size: 16px !important;
    }

    .react-datepicker__input-container input {
        font-size: 16px !important;
    }

    #react-select-3-input {
        width: 100% !important;
    }

    .ratedStatusfieldText .hoverRating:hover .ratingComment {
        display: block;
        border: 1px solid;
        position: absolute;
        background-color: white;
        margin-top: -40px;
        margin-left: -45px;
        padding: 10px;
        color: #4a4a4a;
        border-radius: 14px;
    }

    .rejectedStatusfieldText .hoverCanceled:hover .canceledComment {

        margin-top: 0px;
        margin-left: -10px;

    }
}