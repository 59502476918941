.timingsParent {
    display: flex;
    position: relative;
    margin: 0 0 12px 0;
    min-height: 52px;
}

.washerfieldText {
    color: #4a4a4a !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin: 0;
}

.pac-target-input {
    left: 24px !important;
}

.timingsParent .dayParent {
    display: flex;
    position: relative;
    align-items: baseline;
    justify-content: space-between;
    min-width: 150px;
    margin-top: 8px;
    margin-right: 34px;
}

.timingsParent .dayParent .day {
    font-size: 14px;
    font-weight: 500;
}

.timingsParent .dateContainer {
    position: relative;
}

.timingsParent .dateContainer .date2Container {
    display: flex;
    align-items: baseline;
    position: relative;
    margin: 0 0 10px 10px;
}

.timingsParent .dateContainer .date2Container .react-datepicker-wrapper {
    width: auto !important;
}

.timingsParent .dateContainer .date2Container .customDatePicker {
    margin: 0 10px;
    width: 105px !important;
    border-radius: 4px !important;
}

@media only screen and (max-width: 820px) {
    .timingsParent .dateContainer .date2Container .customDatePicker {
        margin: 10px 10px 0px 0px;
    }
}

.timingsParent .dateContainer .date2Container .addTimings {
    font-size: 14px;
    font-weight: 600;
    color: #3498db;
    cursor: pointer;
}

.timingsParent .dateContainer .date2Container .addTimings i {
    font-size: 14px;
    font-weight: 600;
    color: #3498db;
}

.timingsParent .dateContainer .date2Container .removeTimings i {
    font-size: 14px;
    font-weight: 600;
    color: #d7d7d7;
    cursor: pointer;
}
.popperClass{
    width: 360px;
}

.customInput {
    border-radius: 4px;
}

.dropzone {
    border: 1px dotted #d7d7d7 !important
}

.btn-primary:not(:disabled):not(.disabled).active {
    background-color: #3498db;
    border-color: #3498db;
}

.dropdown-toggle.btn.btn-outline-primary {
    padding: 10px;
}

.fileContainer {
    box-shadow: none;
    border: 1px solid #d7d7d7 !important
}
