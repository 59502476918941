.serviceCard {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 1.5rem !important;
    align-items: center;
}

.buttonsClass {
    display: flex;
    align-items: center;
    justify-content: center;
}
.deleteServieButton{
    margin-right: 8px;
}