.custuomReactTable .rt-table {
  overflow: visible;
}

.custuomReactTable .rt-table .rt-thead.-header {
  padding: 10px 1.5rem !important;
}

.providerfieldText {
  color: #4a4a4a !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin: 0;
}

.custuomReactTable .rt-table .rt-tbody {
  overflow: visible;
  margin-bottom: 20px;
}

.custuomReactTable .rt-table .rt-tbody .rt-tr-group {
  padding: 0 1.5rem !important;
  background: white;
  border-radius: .75rem;
  margin: 5px;
  box-shadow: 0 1px 15px rgba(0, 0, 0, .04), 0 1px 6px rgba(0, 0, 0, .04);
  border-collapse: initial;
  border-spacing: 0 .6rem;
}

.custuomReactTable .rt-table .rt-tbody .rt-tr-group .rt-tr.-odd {
  align-items: center;
}

.custuomReactTable .rt-table .rt-tbody .rt-tr-group .rt-tr.-even {
  align-items: center;
}

.custuomReactTable .rt-table .rt-tbody .rt-tr-group .rt-tr.-odd .rt-td {
  overflow: visible;
}

.custuomReactTable .rt-table .rt-tbody .rt-tr-group .rt-tr.-even .rt-td {
  overflow: visible;
}
.servicesTable .rt-table .rt-tbody .rt-tr-group .rt-tr.-odd {
  align-items: center;
}